import * as React from "react"
import Layout from "../containers/Layout"

// styles
const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}

// markup
const Loading = () => {
  return (
    <Layout>
    <main style={pageStyles}>
      <div className="container text-center">
        <h1>Loading...</h1>
      </div>
    </main>
    </Layout>
  )
}

export default Loading
